@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    height: 100%;
}

body {
    background-image: url('assets/bg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.forest-bg {
    background: url('assets/forest.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    aspect-ratio: 16/9;
    width: 100%;
    height: 100%;
}
